<template>
    <div class="divide-y-2 divide-default">
        <v-card-content v-if="[2, 3].includes(job.order_line_status_id) && ($me.hasPermission(['order-lines.update', 'order-lines.add-assistant']) || $me.hasPermission(['order-lines.change-primary']))">
            <div class="sm:flex sm:space-x-6 space-y-2 sm:space-y-0">
                <div class="w-full">
                    <div class="flex flex-row w-full space-x-2">
                        <v-form-select v-model="assistantForm.user_id" :options="assistantOptions" :disabled="isLoading" default-label="Select Sherpa" class="w-full" />
                        <v-button v-show="assistantForm.user_id" color="danger" class="w-auto" @click="assistantForm.user_id = null">
                            <i class="fas fa-times" />
                        </v-button>
                    </div>
                </div>

                <v-button text-alignment="center" class="w-full" :disabled="isLoading || assistantForm.user_id === null || [1, 4, 5].includes(job.order_line_status_id) || !$me.hasPermission(['order-lines.update', 'order-lines.add-assistant'])" @click="addAssistant(assistantForm)">
                    Add Sherpa
                </v-button>

                <v-button v-if="$me.hasPermission(['order-lines.update', 'order-lines.change-primary'])" text-alignment="center" class="w-full" :disabled="isLoading || assistantForm.user_id === null || [1, 4, 5].includes(job.order_line_status_id)" @click="makePrimary(assistantForm)">
                    Make Primary
                </v-button>
            </div>
        </v-card-content>

        <v-table-container>
            <v-table>
                <v-table-head>
                    <v-table-row>
                        <template v-for="({ label, fullWidth = true }, key) in assistantColumns">
                            <v-table-header :key="key" :full-width="fullWidth" class="w-1/3">
                                {{ label }}
                            </v-table-header>
                        </template>

                        <v-table-header v-if="userCanRemoveAssistant" :full-width="false" align="center">
                            Actions
                        </v-table-header>
                    </v-table-row>
                </v-table-head>

                <v-table-body>
                    <template v-for="(assistant, assistantIndex) in users">
                        <v-table-row :key="'assistant_' + assistantIndex">
                            <template v-for="({ fullWidth = true, formatter }, key) in assistantColumns">
                                <v-table-data :key="'assistant_' + assistantIndex + key" :full-width="fullWidth" class="w-1/3">
                                    <template v-if="typeof formatter == 'function'">
                                        <v-text>
                                            {{ formatter(assistant) }}
                                        </v-text>
                                    </template>

                                    <template v-else>
                                        <v-text>
                                            {{ assistant[key] }}
                                        </v-text>
                                    </template>
                                </v-table-data>
                            </template>

                            <v-table-data v-if="userCanRemoveAssistant" :full-width="false" align="center" class="flex space-x-2 items-center justify-center">
                                <v-link v-if="!(assistant.order_line_user_relationship_type_id != 1 || [4, 5].includes(job.order_line_status_id) || !$me.hasPermission('order-lines.remove-assistant'))" color="secondaryInverse" @click="removeAssistant(assistant)">
                                    <i class="fas fa-trash" />
                                </v-link>
                            </v-table-data>
                        </v-table-row>
                    </template>
                </v-table-body>
            </v-table>
        </v-table-container>
    </div>
</template>

<script>
import JobService from "@/services/modules/job-service";

export default {
    props: {
        job: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            assistantColumns: {
                name: {
                    label: "Name",
                    fullWidth: false,
                },
                order_line_user_relationship_type_name: {
                    label: "Type",
                    formatter: (row) => row.order_line_user_relationship_type_name,
                    fullWidth: false,
                },
                pivot_updated_at: {
                    label: "Time",
                    formatter: (row) => row.pivot_updated_at,
                    fullWidth: false,
                },
            },
            users: [],
            assistantForm: {
                user_id: null,
            },
            assistants: [],
            jobService: JobService,
        };
    },
    computed: {
        userCanRemoveAssistant() {
            // if user is not assigned but has the remove assistant permission, they can still remove assistant.
            if (this.$me.hasPermission("order-lines.remove-assistant")) {
                return true;
            }

            const assignedUser = this.users.find((user) => user.user_id === this.$me.getUser().user_id);

            if (!assignedUser) {
                return false;
            }

            // check if user is assigned to the job and is primary
            if (assignedUser.order_line_user_relationship_type_id !== 2) {
                return false;
            }

            return true;
        },
        userCanChangePrimaryUser() {
            if (this.$me.hasPermission("order-lines.change-primary")) return true;

            return false;
        },
        userIsAssignedToJob() {
            return this.users.map((user) => user.user_id).includes(this.$me.getUser().user_id);
        },
        assistantOptions() {
            const userIds = this.users.reduce((total, user) => {
                total.push(user.user_id);
                return total;
            }, []);

            return this.assistants.reduce((total, assistant) => {
                if (!userIds.includes(assistant.user_id)) {
                    total.push({
                        label: assistant.name,
                        value: assistant.user_id,
                    });
                }

                return total;
            }, []);
        },
    },
    watch: {
        "job.order_line_status_id": {
            handler() {
                this.initialize();
            },
        },
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.fetchUsers();

            if (this.job.order_line_status_id !== 1) {
                this.fetchAssignableUsers();
            }
        },
        fetchUsers() {
            this.jobService.getUsers(this.job.order_line_id)
                .then((response) => {
                    this.users = response.data;
                });
        },
        fetchAssignableUsers() {
            this.isLoading = true;

            this.jobService.getAssignableUsers(this.job.order_line_id)
                .then((response) => {
                    this.assistants = response.data.data;
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
        addAssistant(payload) {
            this.toggleLoading();
            this.$store.dispatch("jobModule/addAssistant", { id: this.job.order_line_id, payload })
                .then(({ data }) => {
                    this.$emit("job-updated", data.data);
                })
                .finally(() => {
                    this.assistantForm.user_id = null;
                    this.fetchUsers();
                    this.toggleLoading();
                });
        },
        removeAssistant(user) {
            const payload = {
                user_id: user.user_id,
            };
            this.toggleLoading();
            this.$store.dispatch("jobModule/removeAssistant", { id: this.job.order_line_id, payload })
                .then(({ data }) => {
                    this.$emit("job-updated", data.data);
                })
                .finally(() => {
                    this.assistantForm.user_id = null;
                    this.fetchUsers();
                    this.toggleLoading();
                });
        },
        makePrimary(user) {
            const payload = {
                user_id: user.user_id,
            };
            this.toggleLoading();
            this.$store.dispatch("jobModule/makePrimary", { id: this.job.order_line_id, payload })
                .then(({ data }) => {
                    this.$emit("job-updated", data.data);
                })
                .finally(() => {
                    this.assistantForm.user_id = null;
                    this.fetchUsers();
                    this.toggleLoading();
                });
        },
    },
};
</script>
