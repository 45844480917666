<template>
    <div>
        <v-table-container>
            <v-table>
                <v-table-head>
                    <v-table-row>
                        <v-table-header>
                            Fee Type
                        </v-table-header>
                        <v-table-header align="right">
                            Amount
                        </v-table-header>
                    </v-table-row>
                </v-table-head>

                <v-table-body>
                    <template v-if="isLoading">
                        <v-loader />
                    </template>

                    <template v-if="!isLoading && orderLineFees">
                        <v-table-row v-for="(orderLineFee, orderLineFeeIndex) in orderLineFees" :key="'orderLineFee_' + orderLineFeeIndex">
                            <v-table-data>
                                <v-text>
                                    {{ orderLineFee.order_line_fee_type.name }}
                                </v-text>
                            </v-table-data>
                            <v-table-data align="right">
                                <v-text>
                                    {{ orderLineFee.amount | numberFormat }}
                                </v-text>
                            </v-table-data>
                        </v-table-row>
                    </template>

                    <template v-if="!isLoading && (!orderLineFees || orderLineFees.length === 0)">
                        <v-table-row-no-results />
                    </template>
                </v-table-body>
            </v-table>
        </v-table-container>
    </div>
</template>

<script>
import JobService from "@/services/modules/job-service";

export default {
    props: {
        job: {
            type: Object,
            required: true,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            jobService: JobService,
            orderLineFees: null,
        };
    },
    watch: {
        "job.order_line_status_id": {
            handler() {
                this.getOrderLineFees();
            },
        },
    },
    created() {
        this.getOrderLineFees(this.query);
    },
    methods: {
        getOrderLineFees(payload = {}) {
            this.isLoading = true;

            this.jobService.getOrderLineFees(this.job.order_line_id, payload)
                .then((response) => {
                    this.orderLineFees = response.data.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
