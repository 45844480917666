<template>
    <v-slide-over v-if="jobId" v-model="input" title="">
        <template v-slot:header>
            <portal-target name="job-slide-over" />
        </template>

        <v-model-form :id="jobId" module="jobModule">
            <template v-slot:default="{ model, refreshModel }">
                <div class="divide-y-2 divide-default">
                    <portal to="job-slide-over">
                        <div v-if="model.is_fast_pass" class="w-full bg-black px-4 rounded-md">
                            <div class="flex-1 flex items-center py-1 space-x-2">
                                <span class="flex rounded-lg">
                                    <svg class="h-5 w-5 text-secondary-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />
                                    </svg>
                                </span>

                                <p class="font-medium text-gray-300 truncate py-1">
                                    <span>
                                        Fast Pass
                                    </span>
                                </p>
                            </div>
                        </div>
                    </portal>

                    <div>
                        <v-section>
                            <v-section-heading>
                                <template v-slot:default>
                                    Order Details
                                </template>

                                <template v-slot:description>
                                    View information about the order for this job.
                                </template>
                            </v-section-heading>

                            <v-order-details
                                :exclude-keys="excludeKeys"
                                :order-line="model"
                            />
                        </v-section>

                        <v-section>
                            <template v-if="$me.getUser() && $me.hasPermission('order-lines.update')">
                                <template v-if="model.taken_by && [1, 2, 3].includes(model.order_line_status_id)">
                                    <template v-if="model.taken_by.user_id === $me.getUser().user_id || $me.hasPermission('is_admin')">
                                        <v-section-heading>
                                            <template v-slot:default>
                                                Job Actions
                                            </template>

                                            <template v-slot:description>
                                                Actions you can take on the job.
                                            </template>
                                        </v-section-heading>

                                        <v-section-toolbar>
                                            <div class="col-span-full flex flex-col sm:flex-row sm:space-x-6 space-y-2 sm:space-y-0">
                                                <template v-if="$me.hasPermission('order-lines.untake')">
                                                    <v-button color="primary" :disabled="isLoading" @click.stop="updateJob({ function: jobService.untakeJob, refreshModel })">
                                                        Un-Take Job
                                                    </v-button>
                                                </template>

                                                <template v-if="model.order_line_status_id == 2 && model.taken_by.user_id === $me.getUser().user_id">
                                                    <v-button color="primary" :disabled="isLoading" @click.stop="updateJob({ function: jobService.startJob, refreshModel })">
                                                        Start Job
                                                    </v-button>
                                                </template>

                                                <template v-if="model.order_line_status_id == 3 && model.taken_by.user_id === $me.getUser().user_id">
                                                    <v-button color="primary" :disabled="isLoading" @click.stop="updateJob({ function: jobService.completeJob, refreshModel })">
                                                        Complete Job
                                                    </v-button>
                                                </template>

                                                <template v-if="$me.hasPermission('is_admin') || model.taken_by.user_id === $me.getUser().user_id">
                                                    <template v-if="model.customer_conversation">
                                                        <v-button color="primary" :disabled="isLoading" :to="{ name: 'conversations.show', params: { conversation: model.customer_conversation.conversation_id } }">
                                                            View Conversation
                                                        </v-button>
                                                    </template>
                                                    <template v-else>
                                                        <v-button color="primary" :disabled="isLoading" @click="createConversation(model.customer_id)">
                                                            Start Conversation
                                                        </v-button>
                                                    </template>
                                                </template>
                                            </div>
                                        </v-section-toolbar>
                                    </template>
                                </template>

                                <template v-else-if="model.taken_by === null && $me.hasPermission('order-lines.update') && model.order_line_status_id == 1">
                                    <v-section-heading>
                                        <template v-slot:default>
                                            Job Actions
                                        </template>

                                        <template v-slot:description>
                                            Actions you can take on the job.
                                        </template>
                                    </v-section-heading>

                                    <v-section-toolbar>
                                        <template v-if="!isMaximumIncompleteOrdersReached">
                                            <v-button color="primary" :disabled="isLoading" @click.stop="updateJob({ function: jobService.takeJob, refreshModel })">
                                                Take Job
                                            </v-button>
                                        </template>

                                        <template v-if="$me.hasPermission('is_admin')">
                                            <div class="flex flex-col sm:block flex-shrink-0 col-span-2">
                                                <template v-if="model.customer_conversation">
                                                    <v-button color="primary" :disabled="isLoading" :to="{ name: 'conversations.show', params: { conversation: model.customer_conversation.conversation_id } }">
                                                        View Conversation
                                                    </v-button>
                                                </template>
                                                <template v-else>
                                                    <v-button color="primary" :disabled="isLoading" @click="createConversation(model.customer_id)">
                                                        Start Conversation
                                                    </v-button>
                                                </template>
                                            </div>
                                        </template>
                                    </v-section-toolbar>

                                    <v-card-content v-if="isMaximumIncompleteOrdersReached">
                                        <v-text>
                                            You have reached your limit of {{ maximumOrders }} active job(s).
                                        </v-text>
                                    </v-card-content>
                                </template>
                            </template>

                            <template v-else-if="model.customer_id === $me.getUser().user_id && $me.hasPermission('conversations.create')">
                                <v-section-heading>
                                    <template v-slot:default>
                                        Job Actions
                                    </template>

                                    <template v-slot:description>
                                        Actions you can take on the job.
                                    </template>
                                </v-section-heading>

                                <v-section-toolbar>
                                    <div class="flex flex-col sm:block flex-shrink-0 col-span-2">
                                        <template v-if="model.customer_conversation">
                                            <v-button color="primary" :disabled="isLoading" :to="{ name: 'conversations.show', params: { conversation: model.customer_conversation.conversation_id } }">
                                                View Conversation
                                            </v-button>
                                        </template>
                                        <template v-else>
                                            <v-button color="primary" :disabled="isLoading" @click="createConversation(model.customer_id)">
                                                Start Conversation
                                            </v-button>
                                        </template>
                                    </div>
                                </v-section-toolbar>
                            </template>
                        </v-section>

                        <v-section-group>
                            <v-section-heading>
                                <template v-slot:default>
                                    Job Information
                                </template>

                                <template v-slot:description>
                                    The general information about the job.
                                </template>
                            </v-section-heading>

                            <v-table-container>
                                <v-table>
                                    <v-table-head>
                                        <v-table-row>
                                            <v-table-header :full-width="false">
                                                Job
                                            </v-table-header>
                                            <v-table-header>Product</v-table-header>
                                            <v-table-header>Qty</v-table-header>
                                            <v-table-header>Status</v-table-header>
                                            <v-table-header>Price</v-table-header>
                                        </v-table-row>
                                    </v-table-head>

                                    <v-table-body>
                                        <v-table-row>
                                            <v-table-data :full-width="false" align="right">
                                                <p class="text text-default text-gray-300">
                                                    {{ model.order_line_id }}
                                                </p>
                                            </v-table-data>
                                            <v-table-data>
                                                <p class="text text-default text-gray-300">
                                                    {{ model.product.name }}
                                                </p>
                                            </v-table-data>
                                            <v-table-data align="right">
                                                <p class="text text-default text-gray-300">
                                                    {{ model.quantity }}
                                                </p>
                                            </v-table-data>
                                            <v-table-data>
                                                <p class="text text-default text-gray-300">
                                                    {{ model.order_line_status.name }}
                                                </p>
                                            </v-table-data>
                                            <v-table-data align="right">
                                                <p class="text text-default text-gray-300">
                                                    {{ model.display_price | numberFormat }}
                                                </p>
                                            </v-table-data>
                                        </v-table-row>
                                    </v-table-body>
                                </v-table>
                            </v-table-container>
                        </v-section-group>

                        <v-section-group v-if="model.users && model.users.length">
                            <v-section-heading class="border-b-2 border-default">
                                <template v-slot:default>
                                    Sherpas
                                </template>

                                <template v-slot:description>
                                    Users assigned to process the job.
                                </template>
                            </v-section-heading>

                            <v-assistants-table :job="{ order_line_id: model.order_line_id, users: model.users, order_line_status_id: model.order_line_status_id }" @job-updated="refreshModel" />
                        </v-section-group>

                        <v-section-group v-if="showMetaInformation(model)">
                            <v-section-heading>
                                <template v-slot:default>
                                    Meta Information
                                </template>

                                <template v-slot:description>
                                    Job specific information for order completion.
                                </template>
                            </v-section-heading>

                            <v-meta-information-table :job="model" />
                        </v-section-group>

                        <v-section-group>
                            <v-section-heading>
                                <template v-slot:default>
                                    Tips
                                </template>

                                <template v-slot:description>
                                    Additional payment given to Sherpas by the customer.
                                </template>
                            </v-section-heading>

                            <v-order-line-tips-table :query="{ parent_order_line_id: model.order_line_id }" />
                        </v-section-group>

                        <v-section-group v-if="$me.hasPermission('order-fees.read')">
                            <v-section-heading>
                                <template v-slot:default>
                                    Order Line Fees
                                </template>

                                <template v-slot:description>
                                    Fees associated with the job.
                                </template>
                            </v-section-heading>

                            <v-order-line-fees-table :job="model" :query="{ is_addon: false }" />
                        </v-section-group>
                    </div>
                </div>
            </template>
        </v-model-form>
    </v-slide-over>
</template>

<script>
import AssistantsTable from "@/components/application/job/job-assistants-table.vue";
import ConversationService from "@/services/modules/conversation-service";
import JobService from "@/services/modules/job-service";
import UserService from "@/services/modules/user-service";
import MetaInformationTable from "@/components/application/job/job-meta-information-table.vue";
import OrderLineFeesTable from "@/components/application/job/job-order-line-fees-table.vue";
import OrderLineTipsTable from "@/components/application/order-line-tips/order-line-tips-table.vue";
import OrderDetails from "@/components/application/order/order-details.vue";

export default {
    name: "JobSlideOver",
    components: {
        "v-assistants-table": AssistantsTable,
        "v-meta-information-table": MetaInformationTable,
        "v-order-line-fees-table": OrderLineFeesTable,
        "v-order-line-tips-table": OrderLineTipsTable,
        "v-order-details": OrderDetails,
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        jobId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            input: false,
            isMaximumIncompleteOrdersReached: false,
            maximumOrders: 0,
            conversationService: ConversationService,
            jobService: JobService,
            userService: UserService,
            excludeKeys: ["Order Status"],
        };
    },
    watch: {
        value: {
            handler(value) {
                this.input = value;
            },
            immediate: true,
        },
        input: {
            handler(value) {
                this.$emit("input", value);
            },
        },
    },
    created() {
        this.initialize();
    },
    methods: {
        showMetaInformation(model) {
            if (this.$me.hasPermission("order-lines.read.meta")) return true;

            if ([4, 5, 6].includes(model.order_line_status_id)) return false;

            if (!model || !model.users) return false;

            return model.users.map(({ user_id: userId }) => userId).includes(this.$me.getUser().user_id);
        },
        toggleInput() {
            this.input = !this.input;
        },
        initialize() {
            this.getIsMaximumIncompleteOrdersReached();
        },
        getIsMaximumIncompleteOrdersReached() {
            this.isMaximumIncompleteOrdersReached = false;
            this.userService.getIsMaximumIncompleteOrdersReached(this.$me.getUser().user_id)
                .then((response) => {
                    this.isMaximumIncompleteOrdersReached = response.data.maximum_incomplete_orders_reached;
                    this.maximumOrders = response.data.maximum_orders;
                });
        },
        updateJob(payload) {
            this.isLoading = true;

            payload.function(this.jobId, payload)
                .then(() => {
                    this.$emit("job-updated");
                    return payload.refreshModel();
                })
                .catch(() => payload.refreshModel())
                .finally(() => {
                    this.initialize();
                    this.toggleLoading();
                });
        },
        createConversation(userId) {
            console.log(userId);
            this.toggleLoading();
            this.conversationService.store({ user_id: userId })
                .catch((error) => {
                    alert(error.response.data.message);
                    this.toggleLoading();
                }).then((response) => {
                    this.$router.push({ name: "conversations.show", params: { conversation: response.data.data.conversation_id } });
                    this.toggleLoading();
                });
        },
    },
};
</script>
