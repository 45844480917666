<template>
    <div>
        <v-table-container>
            <v-table>
                <v-table-head>
                    <v-table-row>
                        <v-table-header :full-width="false">
                            Key
                        </v-table-header>

                        <v-table-header>
                            Value
                        </v-table-header>
                    </v-table-row>
                </v-table-head>

                <v-table-body>
                    <template v-if="isLoading">
                        <v-loader />
                    </template>

                    <template v-if="!isLoading && metaInformation">
                        <v-table-row v-for="(meta, metaIndex) in metaInformation" :key="'meta_' + metaIndex">
                            <v-table-data :auto-width="true" :full-width="false">
                                <v-text>
                                    {{ meta.display_key ? meta.display_key : 'N/A' }}
                                </v-text>
                            </v-table-data>

                            <v-table-data :full-width="true" :whitespace-no-wrap="false">
                                <v-text>
                                    {{ meta.value ? meta.value : 'N/A' }}
                                </v-text>
                            </v-table-data>
                        </v-table-row>
                    </template>

                    <template v-if="!isLoading && (!metaInformation || metaInformation.length === 0)">
                        <v-table-row-no-results :message="emptyMetaInformationMessage" />
                    </template>
                </v-table-body>
            </v-table>
        </v-table-container>
    </div>
</template>

<script>
import JobService from "@/services/modules/job-service";

export default {
    props: {
        job: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            jobService: JobService,
            metaInformation: null,
        };
    },
    computed: {
        emptyMetaInformationMessage() {
            if (this.job.has_meta_information) return "No results found for your permission level.";

            return "No results found.";
        },
    },
    watch: {
        "job.order_line_status_id": {
            handler() {
                this.getMetaInformation();
            },
        },
    },
    created() {
        this.getMetaInformation();
    },
    methods: {
        getMetaInformation(payload = {}) {
            this.isLoading = true;

            this.jobService.getMetaInformation(this.job.order_line_id, payload)
                .then((response) => {
                    this.metaInformation = response.data.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
